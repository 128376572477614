.App {
  text-align: left;

}

h1{
  margin: 20px;
}

.result{
  margin: 20px;
}

input{
  font-size: 30px;
  margin: 20px;
  border: 5px solid #000;
}

button{
  margin: 5px;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #00000044;
  background-color: rgba(127, 255, 212, 0.5);
}

button:hover{
  border: 1px solid #fff;
}

.reset{
  background-color:coral;
  color: #fff;
}
